"use strict";
/* eslint-disable-next-line no-unused-vars */
function __export(m) {
/* eslint-disable-next-line no-prototype-builtins */
    for (var p in m) if (!exports.hasOwnProperty(p)) exports[p] = m[p];
}
Object.defineProperty(exports, "__esModule", { value: true });
var DA = require('./DA');
exports.DA = DA;
exports.packageId = 'd14e08374fc7197d6a0de468c968ae8ba3aadbf9315476fd39071831f5923662';
